<template>
  <div class="pt-56">
    <v-container>
      <!-- 銀行卡表單 -->
      <v-form
        v-if="routerParams.type && routerParams.type==='bank'"
        ref="form"
        v-model="valid"
      >
        <v-row class="px-1">
          <!-- 開戶姓名 -->
          <v-col
            cols="6"
            class="pt-4 pb-0"
          >
            <v-text-field
              v-model="form.defray_name"
              :label="$t('myCenter.bankAccountName')"
              class="mt-2"
              min-height="36"
              disabled
              outlined
              required
            />
          </v-col>

          <!-- 開戶銀行 -->
          <v-col
            cols="12"
            class="py-0"
          >
            <v-text-field
              v-model="dispalyInput.bankName"
              :label="$t('deposit.bank')"
              class="mt-2"
              max-height="36"
              readonly
              outlined
              required
              :rules="[rules.required.bankCode]"
              :placeholder="$t('form.missBank')"
              @click="dialog.status = true"
            >
              <div
                v-if="activeBank"
                slot="prepend-inner"
                class="bank__inner d-flex align-center p-relative"
              />
              <div slot="append">
                <v-icon size="23">
                  mdi-chevron-right
                </v-icon>
              </div>
            </v-text-field>
          </v-col>

          <!-- 銀行分行(印度盧比顯示) -->
          <v-col
            v-if="currencySymbol==='INR'"
            cols="12"
            class="py-0"
          >
            <v-text-field
              v-model="form.ifsc_code"
              :label="$t('myCenter.bankNameIFSC')"
              class="mt-2"
              max-height="36"
              outlined
              required
              :rules="[
                rules.required.bankNameIFSC,
                rules.ifsc.bankName,
              ]"
              :placeholder="$t('form.missBankNameIFSC')"
            />
          </v-col>

          <!-- 銀行卡號 -->
          <v-col
            cols="12"
            class="py-0"
          >
            <v-text-field
              v-model.trim="form.bank_no"
              :label="$t('myCenter.bankCardNo')"
              class="mt-2"
              max-height="36"
              :placeholder="$t('form.missCardNo')"
              single-line
              outlined
              inputmode="numeric"
              required
              clear-icon="mdi-close-circle"
              clearable
              :rules="[
                rules.required.bankCard,
                rules.bankCard.minLength,
                rules.bankCard.maxLength,
              ]"
              @keyup="bankIdFormat"
            >
              <div slot="clear-icon">
                <v-icon>mdi-close-circle</v-icon>
              </div>
            </v-text-field>
          </v-col>

          <!-- 开户省市 (僅有 cn 需要選擇 「開戶省分」) -->
          <v-col
            v-if="nation === 'china' ? true : false"
            cols="12"
            class="py-0"
          >
            <v-text-field
              class="mt-2"
              :label="$t('myCenter.provinceCity')"
              max-height="36"
              outlined
              required
              readonly
              :placeholder="$t('form.missProvince')"
              :value="bankCitiesInput"
              :rules="[rules.required.provinceNcity]"
              @click="showSheet"
            >
              <div slot="append">
                <v-icon size="23">
                  mdi-chevron-right
                </v-icon>
              </div>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-btn
              block
              height="44"
              class="subtitle-1 rounded-0 white--text"
              color="btn-bg"
              :disabled="!valid"
              @click="submitForm('form')"
            >
              {{ $t('global.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <!-- 虛擬幣表單 -->
      <v-form
        v-if="routerParams.type && routerParams.type==='usdt'"
        ref="usdtForm"
        v-model="valid"
      >
        <v-row class="px-1">
          <!-- 別名 -->
          <v-col
            cols="12"
            class="pt-4 pb-0"
            style="margin-bottom:25px"
          >
            <v-text-field
              v-model="usdtForm.slug"
              :label="$t('myCenter.alias') "
              :placeholder="$t('form.missSlug')"
              class="mt-2"
              :rules="[usdtRules.required.slug]"
              min-height="36"
              outlined
              required
            />
            <span class="font-12">{{ $t('form.slugInfo') }}</span>
          </v-col>

          <!-- 虛擬幣種類 -->
          <v-col
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.cryptoType') }}
            </span><br>
            <v-btn
              v-for="(c,i) in cryptoList"
              :key="i"
              depressed
              active-class="test"
              class="mt-2"
              color="card2"
              :style="c.code===usdtForm.bank_id ? activeItem : ''"
              @click="handleName(c.code)"
            >
              <span
                class="caption mt-1 font-weight-medium rounded-xl"
                :class="[
                  true
                    ? 'primary--text'
                    : 'subtitle--text',
                ]"
              >
                {{ c.name }}
              </span>
            </v-btn>
            <v-text-field
              v-model="usdtForm.bank_id"
              class="hidden-field"
              :rules="[usdtRules.required.bank_id]"
            />
          </v-col>

          <!-- 虛擬幣協議 -->
          <v-col
            v-if="usdtForm.bank_id"
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.cryptoProtocol') }}
            </span><br>
            <v-btn
              v-for="(p,i) in protocolList"
              :key="i"
              depressed
              active-class="test"
              class="mt-2 protocol-btn"
              color="card2"
              :style="p.code===usdtForm.protocol ? activeItem : ''"
              @click="handleProtocol(p.code)"
            >
              <span
                class="caption mt-1 font-weight-medium rounded-xl"
                :class="[
                  true
                    ? 'primary--text'
                    : 'subtitle--text',
                ]"
              >
                {{ p.name }}
              </span>
            </v-btn>
            <v-text-field
              v-model="usdtForm.protocol"
              class="hidden-field"
              :rules="[usdtRules.required.protocol]"
            />
          </v-col>

          <!-- 錢包地址 -->
          <v-col
            cols="12"
            class="pt-4 pb-0"
          >
            <v-text-field
              v-model.trim="usdtForm.bank_no"
              :label="$t('myCenter.cryptoAdress')"
              :placeholder="$t('form.missWalletAdress')"
              class="mt-2"
              :rules="[
                usdtRules.required.bank_no,
                usdtRules.usdt.adress,
              ]"
              min-height="36"
              outlined
              required
              clearable
              clear-icon="mdi-close-circle"
            />
            <span class="font-12">{{ $t('myCenter.usdtAlert') }}</span>
            <br><br>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-btn
              block
              height="44"
              class="subtitle-1"
              color="primary"
              :disabled="!valid"
              @click="submitForm('usdtForm')"
            >
              {{ $t('global.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <!-- 選擇銀行 Dailog -->
    <v-dialog
      v-model="dialog.status"
      scrollable
      hide-overlay
    >
      <v-card class="bg">
        <v-card-title class="primary d-flex justify-center white--text">
          {{ $t('form.missBank') }}
        </v-card-title>
        <!-- 銀行列表 -->
        <v-list
          class="bg"
          flat
        >
          <v-list-item-group
            color="primary"
          >
            <v-list-item
              v-for="(bankItem, index) in filterBankList"
              :key="bankItem._id"
              class="px-5"
              @click="setBankId(bankItem.code)"
            >
              <v-list-item-content>
                <v-list-item-title
                  :class="{'mb-3': index !== filterBankList.length - 1 }"
                  v-text="bankItem.name"
                />
                <v-divider v-if="index !== filterBankList.length - 1" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- 開戶省市 Sheet -->
    <v-bottom-sheet v-model="sheet.status">
      <v-sheet class="text-center">
        <v-list height="318px">
          <!-- province -->
          <template v-if="!form.bank_province">
            <v-subheader class="px-6">
              <span class="text-h6 title--text">
                {{ $t('myCenter.selectProvince') }}
              </span>
              <v-spacer />
              <v-icon
                color="title"
                @click="closeSheet"
              >
                mdi-close
              </v-icon>
            </v-subheader>
            <div class="overflow-auto h-318 pb-56 list">
              <v-list-item
                v-for="(item, province) in sheet.cities"
                :key="province"
                class="text-left px-6"
                @click="setProvince(province)"
              >
                <v-list-item-title>{{ province }}</v-list-item-title>
              </v-list-item>
            </div>
          </template>

          <!-- cities -->
          <template v-else>
            <v-subheader class="px-6">
              <v-icon
                class="mr-6"
                color="primary"
                @click="resetProvince"
              >
                mdi-chevron-left
              </v-icon>
              <span class="text-h6 title--text">
                {{ form.bank_province }}
              </span>
              <v-spacer />
              <v-icon
                color="title"
                @click="closeSheet"
              >
                mdi-close
              </v-icon>
            </v-subheader>
            <div class="overflow-auto h-318 pb-56 list">
              <v-list-item
                v-for="(city, province) in sheet.cities[form.bank_province]"
                :key="province"
                class="text-left px-6"
                @click="setCities(city)"
              >
                <v-list-item-title>{{ city }}</v-list-item-title>
              </v-list-item>
            </div>
          </template>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import addCardMixin from '../mixins/addCardMixin'

export default {
  mixins: [addCardMixin],
}
</script>

<style lang="scss" scoped>
.bank {
	&__icon {
		display: inline-block;
		width: 18px;
		height: 18px;
	}
}

.h-318 {
	height: 318px;
}

.p-fixed {
	top: 0;
	z-index: 2;
}

.pt-110px {
	padding-top: 110px;
}

.btn-bg {
  background: linear-gradient(180deg, #E32A10 0%, #9F1E0B 100%);
}
.font-12{
  font-size: 12px;
}
.protocol-btn+.protocol-btn{
  margin-left: 10px;
}

</style>
